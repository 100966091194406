var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"max-width":"200px"}},[_c('VsSelect',{staticClass:"vs-mb-4",attrs:{"options":[
                {
                    label: _vm.$t('lists.contact.eplus.orders'),
                    value: 'close',
                },
                {
                    label: _vm.$t('lists.contact.eplus.cartOpen'),
                    value: 'open',
                } ]},model:{value:(_vm.filters.cartStatus),callback:function ($$v) {_vm.$set(_vm.filters, "cartStatus", $$v)},expression:"filters.cartStatus"}})],1),_c('VsTable',{staticClass:"vs-relative",attrs:{"loading":_vm.loading,"items":_vm.carts,"headers":_vm.tableHeaders},scopedSlots:_vm._u([{key:"col-productNumber",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticClass:"vs-flex vs-items-center vs-gap-2"},[(row.products.data && row.products.data.length > 0)?_c('span',[_vm._v(" "+_vm._s(row.products.data.reduce( function (accumulator, currentValue) { return accumulator + currentValue.quantity; }, 0 ))+" ")]):_c('span',[_vm._v("0")]),(row.products.data && row.products.data.length > 0)?_c('VsButton',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.$refs.vsEplusProductListModal.openModal(_vm.store.id, row.products.data)}}},[_vm._v(_vm._s(_vm.$t('lists.contact.eplus.seeDetail')))]):_vm._e()],1)]}}])}),_c('VsPagination',{staticClass:"vs-mt-8",attrs:{"totalItems":_vm.total,"filterable":"","expand":"","currentPage":_vm.pagination.page,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}),_c('VsEplusProductListModal',{ref:"vsEplusProductListModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }