



























































import { Component, Vue, Watch } from 'vue-property-decorator'
import {
    getStores,
    getStoreCarts,
} from '@/api/consoleApi/stores'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import VsEplusProductListModal from '@/modules/lists/components/VsEplusProductListModal/Index.vue'
import { UserModule } from '@/store/modules/user'
@Component({
    name: 'ContactEplus',
    components: {
        VsTable,
        VsEplusProductListModal,
    },
})
export default class extends Vue {
    private loading = false
    private store: any = null
    private carts: any[] = []
    private total = 0
    private filters = {
        cartStatus: 'close',
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    private pagination = {
        page: 1,
        itemsPerPage: 5,
    }

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get isEplusDisabled () {
        return this.userRules.eplusLicense === 0
    }

    get tableHeaders (): TableHeader[] {
        return [
            {
                id: 'cart_id',
                key: 'cart_id',
                label: 'Id ordine',
                visible: true,
            },
            {
                id: 'total',
                key: 'total',
                label: 'Totale',
                visible: true,
                formatter: (value: any) => this.$options?.filters?.formatCurrency(value),
            },
            {
                id: 'productNumber',
                key: 'productNumber',
                label: 'Numero prodotti',
                visible: true,
                formatter: (value: any) => this.$options?.filters?.formatNumber(value),
            },
            {
                id: 'created_at',
                key: 'created_at',
                label: 'Data',
                visible: true,
                formatter: (value: any) => this.$options?.filters?.formatDate(value),
            },
        ]
    }

    async beforeMount () {
        if (this.isEplusDisabled) {
            this.$router.replace({
                name: 'contactDetails',
            })
        }
        await this.getListStore()
        await this.getContactCarts()
    }

    @Watch('filters', { immediate: false, deep: true })
    async resetTable () {
        if (this.pagination.page !== 1) {
            this.pagination.page = 1
        } else {
            await this.getContactCarts()
        }
    }

    private async getListStore () {
        try {
            const resp = await getStores({
                search: `recipient_id:${this.listId}`,
            })
            if (resp.data.data.length > 0) {
                this.store = resp.data.data[0]
            } else {
                this.store = null
            }
        } catch (e) {
            console.log(e)
            this.store = null
        }
    }

    @Watch('pagination', { immediate: false, deep: true })
    private async getContactCarts () {
        if (!this.store) {
            this.carts = []
            return
        }
        this.loading = true
        try {
            const resp = await getStoreCarts(this.store.id, {
                search: `subscriber_id:${this.contactId};status:${this.filters.cartStatus}`,
                searchJoin: 'and',
                order: 'id,desc',
                page: this.pagination.page,
                limit: this.pagination.itemsPerPage,
            })
            this.carts = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.carts = []
        }
        this.loading = false
    }
}
