import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getStores = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/stores`,
        {
            cancelToken,
            params,
        },
    )

export const getStoreCarts = (storeId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/stores/${storeId}/carts`,
        {
            cancelToken,
            params,
        },
    )

export const getStoreProducts = (storeId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/stores/${storeId}/products/all`,
        {
            cancelToken,
            params,
        },
    )

export const getStoreProductById = (storeId: any, productId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/stores/${storeId}/products/${productId}`,
        {
            cancelToken,
        },
    )

export const getStoreCategories = (storeId: any, categoryType: 'category' | 'category_med' | 'category_upper') =>
    serviceConsoleApi.get(
        `/stores/${storeId}/categories/${categoryType}`,
        {
            // signal,
        },
    )

export const getStoreBrands = (storeId: any) =>
    serviceConsoleApi.get(
        `/stores/${storeId}/brands`,
        {
            // signal,
        },
    )
